import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Modal,
  Button,
  Link,
  SvgIcon,
  IconButton,
  Divider,
} from "@material-ui/core";
import icons from "utils/svgIcons";
import ContainerBar from "components/Sections/ContainerBar";
import { useSelector } from "react-redux";
import LaunchIcon from "@material-ui/icons/Launch";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import jsPDF from "jspdf";
import useStyles from "./styles";
import "jspdf-autotable";
import { Skeleton } from "@material-ui/lab";
import volEmergenteAlto from "../../../../img/volEmergenteAlto.png";
import volEmergenteBajo from "../../../../img/volEmergenteBajo.png";

const Reports = () => {
  const listOfZones = [
    "COMERCIAL",
    "COMERCIAL MIXTA 1",
    "RESIDENCIAL 1",
    "RESIDENCIAL MIXTA 1",
    "RESIDENCIAL MIXTA 2",
    "RESIDENCIAL MIXTA 3",
    "COMERCIAL MIXTA 1; RESIDENCIAL 1",
    "COMERCIAL; COMERCIAL MIXTA 1",
    "COMERCIAL MIXTA 1; RESIDENCIAL MIXTA 1; RESIDENCIAL 1",
    "COMERCIAL MIXTA 1; RESIDENCIAL MIXTA 1"
  ];
  const sectionId = useSelector((state) => state.categories.sectionInfo);
  const info = useSelector((state) => state.layers.layerInfo);
  const infoTable = useSelector((state) => state.layers.layerTableData);
  const centro_x =
    (parseFloat(info?.bbox_xmax) + parseFloat(info?.bbox_xmin)) / 2;
  const centro_y =
    (parseFloat(info?.bbox_ymax) + parseFloat(info?.bbox_ymin)) / 2;
  const image =
    process.env.REACT_APP_DATABASE_URL +
    `geoserver/geonode/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&STYLES&CQL_FILTER=INTERSECTS(geom,%20POINT%20(${centro_x}%20${centro_y}))&LAYERS=geonode:visor_informacion2&exceptions=application/vnd.ogc.se_inimage&SRS=EPSG:22172&WIDTH=769&HEIGHT=485&BBOX=${info?.bbox_xmin},${info?.bbox_ymin},${info?.bbox_xmax},${info?.bbox_ymax}`;
  console.log(info);
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      setLoading(false);
      setImageLoaded(true);
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handlePrint = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Agregar el título centrado en la parte superior
    doc.setFontSize(22);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "Información Urbanística",
      pageWidth / 2,
      10,
      null,
      null,
      "center"
    );

    // Agregar la imagen centrada en la parte inferior
    const imgWidth = 50;
    const imgHeight = 50;
    const imgX = (pageWidth - imgWidth) / 2;
    const imgY = 40;
    doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);

    // Agregar el texto debajo de la imagen
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // Definir las columnas de la tabla
    const columns = ["Título", "Descripcion"];

    // Definir los datos de la tabla
    const rows = infoTable?.datos?.map(({ title, description }) =>
      description ? [title, description] : []
    );

    const rows2 = infoTable?.edificabilidad?.aft.map(({ title, description }) =>
      description ? rows.push([title, description]) : []
    );
    const rows4 = infoTable?.edificabilidad?.alert.map(
      ({ title, description }) =>
        description ? rows.push([title, description]) : []
    );

    const rows3 = infoTable?.edificabilidad?.edf.map(({ title, description }) =>
      description ? rows.push([title, description]) : []
    );
    // Agregar la tabla
    doc.setFillColor(243, 191, 0);
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 110,
      headerStyles: {
        fillColor: [243, 191, 0], // Establecer el color de fondo del encabezado
        textColor: [87, 87, 86], //
      },
    });
    doc.setDrawColor(0, 0, 255);
    doc.setTextColor(0, 0, 255);
    doc.setFontSize(12);
    doc.addFont("helvetica", "normal", "normal");
    doc.setFont("helvetica");
    doc.textWithLink(
      "Ver reglamentos de usos permitidos.",
      15,
      doc.autoTable.previous.finalY + 10,
      {
        url: infoTable?.usosPermitidos?.map(({ title }) => title)[0],
        target: "_blank",
      }
    );
    doc.addPage();
    if (listOfZones.includes(info.zonificaci)) {
      doc.addImage(volEmergenteAlto, "PNG", 40, 50, 128, 96);
    } else if (info.zonificaci === "COMERCIAL MIXTA 2") {
      doc.addImage(volEmergenteBajo, "PNG", 40, 50, 128, 96);
    }
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont(undefined, "normal");

    doc.text(
      `AVISO LEGAL: La información provista por este documento es orientativa y no vinculante. 
        Este documento tiene como objetivo brindar información urbanística con carácter orientativo, 
        a los efectos de facilitar la comprensión del Código Urbanístico. Esta información 
        no sustituye las normas legales vigentes ni constituye una copia fiel de los datos en poder de la 
        Municipalidad de Luján de Cuyo. Es responsabilidad del usuario confirmar mediante 
        la vía administrativa pertinente la información provista en este sitio previo a 
        alguna toma de decisión o acción.`,
      pageWidth / 2,
      240,
      null,
      null,
      "center"
    );
    // Guardar el archivo PDF
    doc.save(`Información-Urbanística-${info.nomenclatu}.pdf`);
  };
  const modalBody = (
    <div style={{ heigth: "100%", display: "flex", flexDirection: "column" }}>
      <IconButton
        style={{
          padding: 3,
          right: 5,
          position: "absolute",
          top: 2,
        }}
        data-tut="buttonDelete"
        onClick={() => {
          setOpen(false);
        }}
      >
        <ClearIcon className={classes.iconClose} />
      </IconButton>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{
            backgroundColor: "#F3BF00",
            width: "100%",
            textAlign: "center",
            padding: 10,
          }}
        >
          Información Urbanística
        </Typography>
        {loading && (
          <Skeleton
            variant="rect"
            width={300}
            height={200}
            style={{ margin: "20px" }}
          />
        )}
        {imageLoaded && !loading && (
          <img
            width="50%"
            src={image}
            alt="Imagen del Modal"
            style={{ margin: 20 }}
          />
        )}
      </Box>
      <Divider />
      <Box style={{ maxHeight: 350, overflow: "auto", padding: "0px 10px" }}>
        {infoTable?.datos?.map(({ title, description }, index) => (
          <Box
            className={classes.conteinerDatos}
            style={{ backgroundColor: index % 2 === 0 ? "#FAE599" : "#ffffff" }}
          >
            <Typography className={classes.datosAndDescription}>
              {title}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Box>
        ))}
        <Divider />
        {infoTable?.usosPermitidos?.map(({ title, color }) => (
          <Box
            className={classes.conteinerDatos}
            style={{ backgroundColor: color }}
          >
            <Typography className={classes.datosAndDescription}>
              <a
                href={infoTable?.usosPermitidos?.map(({ title }) => title)[0]}
                target="_blanck"
              >
                Ver reglamentos de usos permitidos.
              </a>
            </Typography>
          </Box>
        ))}
        {infoTable?.edificabilidad?.aft.map(
          ({ title, description }) =>
            description && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 15,
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#F1F1F1",
                    width: "90%",
                    padding: 10,
                  }}
                >
                  <Typography style={{ color: "#E8327C", fontWeight: 700 }}>
                    {title}
                  </Typography>
                  <Typography>{description}</Typography>
                </Box>
              </Box>
            )
        )}
        <Divider />
        {infoTable?.edificabilidad?.alert.map(
          ({ title, description }) =>
            description && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 15,
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#F1F1F1",
                    width: "90%",
                    padding: 10,
                  }}
                >
                  <Typography style={{ color: "#E8327C", fontWeight: 700 }}>
                    {title}
                  </Typography>
                  <Typography>{description}</Typography>
                </Box>
              </Box>
            )
        )}
        <Divider />
        {infoTable?.edificabilidad?.edf.map(({ title, description }, index) => (
          <Box
            className={classes.conteinerDatos}
            style={{
              backgroundColor: index % 2 === 0 ? "#FAE599" : "#ffffff",
            }}
          >
            <Typography className={classes.datosAndDescription}>
              {title}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Box>
        ))}
        <Divider />
        {info &&
          (listOfZones.includes(info.zonificaci) ? (
            <img width="100%" src={volEmergenteAlto} alt="Imagen del Modal" />
          ) : info.zonificaci === "COMERCIAL MIXTA 2" ? (
            <img width="100%" src={volEmergenteBajo} alt="Imagen del Modal" />
          ) : (
            <></>
          ))}

        <Typography style={{ marginTop: 10 }}>
          <span style={{ fontWeight: "bold" }}>AVISO LEGAL</span>: La
          información provista por este documento es orientativa y no
          vinculante. Este documento tiene como objetivo brindar información
          urbanística con carácter orientativo, a los efectos de facilitar la
          comprensión del Código Urbanístico. Esta información no sustituye las
          normas legales vigentes ni constituye una copia fiel de los datos en
          poder de la Municipalidad de Luján de Cuyo. Es responsabilidad del
          usuario confirmar mediante la vía administrativa pertinente la
          información provista en este sitio previo a alguna toma de decisión o
          acción.
        </Typography>
      </Box>
      <Divider />
      <Button style={{}} onClick={handlePrint}>
        <Typography>Descargar</Typography>
        <SvgIcon className={classes.icon}>
          {icons.find((i) => i.id === "downloadReport").path}
        </SvgIcon>
      </Button>
    </div>
  );
  return (
    <ContainerBar className={classes.container} type="list">
      <Box className={classes.sectionTitle}>
        <Box>{icons.find((i) => i.id === "ReportesDrawer")?.path}</Box>
        <Typography className={classes.title}>Reportes</Typography>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={classes.paper}>{modalBody}</Box>
      </Modal>
      {info ? (
        <Box className={classes.conteinerDatos}>
          <Box className={classes.info}>
            <Box>
              <Typography>Nomenclatura catastral:</Typography>
              <Typography>{info.nomenclatu}</Typography>

              <Box>
                <Typography>
                  Dirección:
                  {` ${info.callepadro} ${info.alturapadr}`}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.conteinerDownload}>
              <Button className={classes.buttonDownload} onClick={handleOpen}>
                <InfoIcon fontSize="large" />
              </Button>
            </Box>
          </Box>
          <Link
            href="https://sistemamlc.lujandecuyo.gob.ar/test2/auth/login?token=ulPelSBEPARymC5yr9I5Gc9q50YhdGGT"
            color="inherit"
            target="_blank"
          >
            <Button
              variant="contained"
              color="link"
              startIcon={<LaunchIcon />}
              style={{
                position: "absolute",
                padding: "16px",
                left: "27%",
                bottom: 100,
              }}
            >
              Simulá tu proyecto
            </Button>
          </Link>
        </Box>
      ) : (
        <Box className={classes.iconParcel}>
          {icons.find((i) => i.id === "iconParcel")?.path}
        </Box>
      )}
    </ContainerBar>
  );
};

export default Reports;
