import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import useLayers from 'utils/useLayers'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { actions as layersActions } from 'state/ducks/layers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useMediaQuery } from 'react-responsive'
import { withStyles } from '@material-ui/core/styles'
import LayerBox from './box'
import useFilterRange from './useFilterRange'
import useFilterOptions from './useFilterOptions'
import useStyles from './styles'

const WithDraggable = ({
  dragProvided: { innerRef: dragInnerRef, draggableProps, dragHandleProps },
  dragSnapshot: { isDragging },
  children
}) => (
  <Box
    ref={dragInnerRef}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...draggableProps}
    // eslint-disable-next-line react/jsx-props-no-spreading
    style={{
      ...{ backgroundColor: isDragging ? 'lightblue' : null },
      ...draggableProps.style
    }}
  >
    {children}
  </Box>
)

const LayerBoxWithOptionsSelected = ({
  classes,
  style,
  title,
  name,
  layerId,
  range_filter,
  range_text,
  selection_filter,
  selection_text,
  link,
  urlDownload,
  dragProvided
}) => {
  const dispatch = useDispatch()
  const [optionsSelected, setOptionsSelected] = useState([])
  const [rangeSelected, setRangeSelected] = useState([])
  const handlerChangeOption = (id, isSelected) => {
    if (isSelected) {
      setOptionsSelected([...optionsSelected, id])
    } else {
      setOptionsSelected([...optionsSelected.filter((value) => value !== id)])
    }
  }

  const handlerChangeRange = (value, isSelected) => {
    rangeSelected.pop()
    if (isSelected) {
      setRangeSelected([...rangeSelected, value])
    } else {
      setRangeSelected([
        ...rangeSelected.filter((element) => element !== value)
      ])
    }
  }

  const { rangeOptions } = useFilterRange([range_filter, title, 100])
  const { options } = useFilterOptions([selection_filter, title, 100])
  const [metaData, setMetaData] = useState([])
  useEffect(() => {
    fetch(link)
      .then((data) => data.text())
      .then((data) => {
        setMetaData(data)
      })
  }, [link, title])
  const valores = rangeOptions.map(
    ({
      min, minInclusive, max, maxInclusive
    }) => ({
      minCondition: min !== undefined ? min : minInclusive,
      maxCondition: max !== undefined ? max : maxInclusive
    })
  )
  useEffect(() => {
    const IDs = optionsSelected
    const attribute = selection_filter
    const cql = IDs.length === 0 ? null : `(${attribute} IN ('${IDs.join("', '")}'))`
    dispatch(
      layersActions.addFilter({
        layerId,
        cql,
        type: 'options'
      })
    )
  }, [optionsSelected, dispatch, selection_filter, layerId, rangeOptions])

  useEffect(() => {
    const attribute = range_filter
    if (rangeSelected.length !== 0) {
      const response = `((${attribute}>${rangeSelected[0][0]})AND(${attribute}<${rangeSelected[0][1]}))`
      const cql = rangeSelected.length === 0 ? null : `(${response})`
      dispatch(
        layersActions.addFilter({
          layerId,
          cql,
          type: 'range'
        })
      )
    }
  }, [rangeSelected, dispatch, rangeOptions, range_filter, layerId])

  return (
    <LayerBox
      style={style}
      title={title}
      name={name}
      options={options}
      metaData={metaData}
      optionsSelected={optionsSelected}
      onChangeOption={handlerChangeOption}
      footText=""
      valores={valores}
      rangeSelected={rangeSelected}
      onChangeRange={handlerChangeRange}
      opacityText="Control de Opacidad"
      layerId={layerId}
      range_filter={range_filter}
      range_text={range_text}
      selection_filter={selection_filter}
      selection_text={selection_text}
      link={link}
      urlDownload={urlDownload}
      dragProvided={dragProvided}
    />
  )
}

const LayersBoxes = () => {
  const dispatch = useDispatch()
  const isShow = useSelector((state) => state.categories.sectionOpen)
  const { layers } = useLayers()
  const layersIds = useSelector((state) => state.layers?.ids)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const categoriesOpen = useSelector((state) => state.categories.dropDownOpen)
  // const [layers, setLayers] = useState(getItems(10))
  const classes = useStyles()
  const ExpandIcon = withStyles((theme) => ({
    root: {
      position: 'absolute'
    }
  }))(ExpandMoreIcon)
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    dispatch(
      layersActions.orderLayerChange({
        startIndex: result.source.index,
        endIndex: result.destination.index
      })
    )
  }
  
  return (

    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {({ droppableProps, innerRef, placeholder }) => (
          <Box
            className={!categoriesOpen ? classes.boxMobile: isShow? classes.Container: classes.Container2}
            ref={innerRef}
            {...droppableProps}
          >
            <>
              {isTabletOrMobile && layersIds.length && (
              <Box style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent' }}>
                <Accordion key="capasActivas" style={{ backgroundColor: 'transparent' }} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandIcon color="primary" />}
                    className={classes.acorddionBox}
                  />
                  <AccordionDetails style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent' }}>
                    {layers.map(
                      ({
                        id, name, title, range_filter, link, urlDownload, selection_text, selection_filter, range_text
                      }, index) => (
                        <Draggable key={title} draggableId={title} index={index}>
                          {(dragProvided, dragSnapshot) => (
                            <WithDraggable
                              dragProvided={dragProvided}
                              dragSnapshot={dragSnapshot}
                            >

                              <LayerBoxWithOptionsSelected
                                key={id}
                                layerId={id}
                                title={title}
                                name={name}
                                range_filter={range_filter}
                                range_text={range_text}
                                selection_filter={selection_filter}
                                selection_text={selection_text}
                                classes={classes}
                                link={link}
                                urlDownload={urlDownload}
                                dragProvided={dragProvided}
                              />
                            </WithDraggable>
                          )}
                        </Draggable>
                      )
                    )}
                    {placeholder}
                  </AccordionDetails>
                </Accordion>
              </Box>
              )}
              {!isTabletOrMobile && layersIds.length && (
                layers.map(
                  ({
                    id, name, title, range_filter, link, urlDownload, selection_text, selection_filter, range_text
                  }, index) => (
                    <Draggable key={title} draggableId={title} index={index}>
                      {(dragProvided, dragSnapshot) => (
                        <WithDraggable
                          dragProvided={dragProvided}
                          dragSnapshot={dragSnapshot}
                        >
                          <LayerBoxWithOptionsSelected
                            key={id}
                            layerId={id}
                            title={title}
                            name={name}
                            range_filter={range_filter}
                            range_text={range_text}
                            selection_filter={selection_filter}
                            selection_text={selection_text}
                            classes={classes}
                            link={link}
                            urlDownload={urlDownload}
                            dragProvided={dragProvided}
                          />
                        </WithDraggable>
                      )}
                    </Draggable>
                  )
                )
              )}
            </>
            {placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default LayersBoxes
