import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Grid,
  ListItem,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";
import useFontsStyles from "theme/fontsDecorators";
import MapaInteractivoGL from "utils/MapaInteractivoGL";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { getExplorerOptions, getExplorerFilters } from "utils/configQueries";
import icons from "utils/svgIcons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContainerBar from "components/Sections/ContainerBar";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { Autocomplete } from "@material-ui/lab";
import { actions as layersActions } from "state/ducks/layers";

const Reports = () => {
  const baseUrl = process.env.REACT_APP_DATABASE_URL
  const dispatch = useDispatch();
  const explorerLayers = useSelector((state) => state.layers.explorerLayers);
  const explorerCheckBoxes = useSelector((state) => state.layers.checkBoxes);
  const titleSelect = useSelector((state) => state.layers.explorerTitle);
  const selectAll = useSelector((state) => state.layers.selectAllChecked);
  const [selectAllChecked, setSelectAllChecked] = useState(selectAll);
  const [check, setCheck] = useState();
  const [checkboxes, setCheckboxes] = useState(explorerCheckBoxes);
  const [layerLabel, setLayerLabel] = useState("");
  const [selectedLayer, setSelectedLayer] = useState();
  const [filterList, setFilterList] = useState(["any"]);
  const [value, setValue] = useState(explorerLayers.idRadio);
  const classes = useStyles();

  const decorators = useFontsStyles();

  const checked = getExplorerOptions()[0].options;
  const layers = getExplorerFilters();
  useEffect(() => {
    if (value) {
      setSelectedLayer(explorerLayers);
      handleChangeFilter();
      setFilter(explorerLayers.titleRadio, value);
    }
  }, []);
  const cleanLayer = () => {
    if (explorerLayers.idRadio) {
      dispatch(layersActions.addExplorerLayer({}));
      setLayerLabel("");
      const layer = layers.filter((e) => e.id === explorerLayers.idRadio);
      setSelectedLayer();
      setValue("");

      const { map } = MapaInteractivoGL();
      if (map) {
        const existLayer = map.getLayer(layer[0].layers.id);
        if (existLayer) {
          map.removeLayer(layer[0].layers.id);
          map.removeSource(layer[0].layers.id);
        }
      }
    }
  };
  const setFilter = (title, value) => {
    setLayerLabel(title);
    const layer = layers.filter((e) => e.id === value);
    setSelectedLayer(layer);
    const tiles = baseUrl+layer[0].layers.options.source.tiles;
    dispatch(
      layersActions.addExplorerLayer({
        titleRadio: title,
        idRadio: value,
        id: layer[0].layers.id,
        type: layer[0].layers.type,
        sourceLayer: layer[0].layers.options.sourceLayer,
        paint: layer[0].layers.options.paint,
        source: {
          id: layer[0].layers.id,
          type: layer[0].layers.options.source.type,
          tiles: layer[0].layers.options.source.tiles,
        },
        filter: filterList,
      })
    );
    removeAndAddLayer({
      layer: {
        id: layer[0].layers.id,
        type: layer[0].layers.type,
        "source-layer": layer[0].layers.options.sourceLayer,
        paint: layer[0].layers.options.paint,
        source: {
          id: layer[0].layers.id,
          type: layer[0].layers.options.source.type,
          tiles: [tiles],
        },
        filter: filterList,
      },
    });
  };
  const getFilters = (id) => {
    if (id === "Height") {
      checked.map(({ id, items, title }) => {
        if (id === "Altura") {
          dispatch(layersActions.setExplorerTitle(title));
          setCheckboxes(items);
          items.map(({ filter }) => {
            filterList.push(filter);
          });
        }
      });
    } else if (id === "Incidence") {
      checked.map(({ id, items, title }) => {
        if (id === "Area") {
          dispatch(layersActions.setExplorerTitle(title));
          setCheckboxes(items);
          items.map(({ filter }) => {
            filterList.push(filter);
          });
        }
      });
    } else if (id === "Distritos") {
      checked.map(({ id, items, title }) => {
        if (id === "Distritos") {
          dispatch(layersActions.setExplorerTitle(title));
          setCheckboxes(items);
          items.map(({ filter }) => {
            filterList.push(filter);
          });
        }
      });
    } else {
      checked.map(({ id, items, title }) => {
        if (id === "Fos_max") {
          dispatch(layersActions.setExplorerTitle(title));
          setCheckboxes(items);
          items.map(({ filter }) => {
            filterList.push(filter);
          });
        }
      });
    }
  };
  const handleChangeLayer = (event) => {
    const id = event.target.value;
    const title = event.target.name;
    setValue(id);
    setSelectAllChecked(true);
    setFilterList(["any"]);
    getFilters(id);
    setFilter(title, id);
  };
  const removeAndAddLayer = ({ layer }) => {
    const { map } = MapaInteractivoGL();
    if (map) {
      const existLayer = map.getLayer(layer.id);
      if (existLayer) {
        map.removeLayer(layer.id);
        map.removeSource(layer.source.id);
        map.addLayer(layer);
      } else {
        map.addLayer(layer);
      }
    }
  };
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes(
      checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: isChecked,
      }))
    );
    setSelectAllChecked(isChecked);
  };
  useEffect(() => {
    if (selectedLayer) handleChangeFilter();
    dispatch(layersActions.setAllchecked(selectAllChecked));
  }, [selectAllChecked]);
  useEffect(() => {
    if (selectedLayer) {
      dispatch(layersActions.addExplorerCheckBoxes(checkboxes));
    }
  }, [checkboxes]);

  useEffect(() => {
    if (selectedLayer) handleChangeFilter();
  }, [check]);

  const handleCheckboxChange = (id) => (event) => {
    const isChecked = event.target.checked;

    setCheckboxes(
      checkboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
    if (isChecked) {
      const allChecked = checkboxes.every((checkbox) => checkbox.checked);
      setSelectAllChecked(allChecked);
      if (!allChecked) {
        check === event.target.key ? setCheck("") : setCheck(event.target.key);
      }
    } else {
      check === event.target.key ? setCheck("") : setCheck(event.target.key);
      setSelectAllChecked(false);
    }
  };
  const handleChangeFilter = () => {
    setFilterList(["any"]);
    const tiles = baseUrl+explorerLayers.source.tiles;
    checkboxes.map(({ filter, checked }) => {
      if (checked) {
        filterList.push(filter);
      }
    });
    removeAndAddLayer({
      layer: {
        id: explorerLayers.id,
        type: explorerLayers.type,
        "source-layer": explorerLayers.sourceLayer,
        paint: explorerLayers.paint,
        source: {
          id: explorerLayers.id,
          type: explorerLayers.source.type,
          tiles: [tiles],
        },
        filter: filterList,
      },
    });
  };
  return (
    <ContainerBar className={classes.container} type="list">
      <Box className={classes.sectionTitle}>
        <Box>{icons.find((i) => i.id === "ExplorarDrawer")?.path}</Box>
        <Typography className={classes.title}>Explorar</Typography>
      </Box>
      <Box className={classes.conteinerDatos}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InputLabel id="demo-simple-select-label">Capas</InputLabel>
          <Button onClick={cleanLayer}>Deseleccionar Capa</Button>
        </Box>
        <FormControl fullWidth>
          <RadioGroup
            aria-label="Capa"
            value={value}
            onChange={handleChangeLayer}
          >
            {layers &&
              layers?.map(({ id, title }) => (
                <FormControlLabel
                  key={id}
                  name={title}
                  value={id}
                  control={<Radio checked={value === id} />}
                  label={title}
                />
              ))}
          </RadioGroup>
        </FormControl>
        {layerLabel && (
          <Box>
            <Box style={{ marginTop: 20 }}>
              <Accordion style={{ border: "1px solid #D9D9D9" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{titleSelect}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {checkboxes && (
                    <Box className={classes.options}>
                      <FormControlLabel
                        style={{ paddingLeft: "10px" }}
                        control={
                          <Checkbox
                            checked={selectAllChecked}
                            onClick={handleSelectAllChange}
                          />
                        }
                        label="Seleccionar todos"
                      />
                      {checkboxes.map(
                        ({
                          subTitle,
                          details,
                          color,
                          id,
                          idLayer,
                          filter,
                          checked,
                        }) => (
                          <ListItem
                            style={{
                              backgroundColor: `${color}`,
                              paddingBottom: 0,
                              paddingTop: 0,
                            }}
                          >
                            <FormControlLabel
                              key={id}
                              control={
                                <Checkbox
                                  key={id}
                                  checked={checked}
                                  onClick={handleCheckboxChange(id)}
                                />
                              }
                            />
                            <Typography
                              variant="subtitle2"
                              className={`${decorators.grey333} ${decorators.bold}`}
                            >
                              {`${subTitle}`}
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  className={`${decorators.grey333}`}
                                >
                                  {details}
                                </Typography>
                              </Box>
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        )}
      </Box>
    </ContainerBar>
  );
};

export default Reports;
