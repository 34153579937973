// import config from 'appConfig.json'
import { element, object } from 'prop-types'
import { set } from 'react-ga'
import icons from 'utils/svgIcons'
import { getLayersAndSector } from 'utils/apiConfig'

let config = null

const mergeConfig = (internalConfig, layers, categories, baseUrl) => {
  const result = {
    ...internalConfig, layers, categories, sources: {}, baseUrl
  }
  layers.forEach((e) => {
    const layerName = e[Object.keys(e)].title
    const {
      name, source_type: sourceType, url_map_libre: urlMapLibre, vt_paint_style: vtPaintStyle, vt_visibility_field: vtVisibilityField
    } = e[Object.keys(e)]
    const firstPropName = Object.keys(vtPaintStyle)[0]
    result.sources[`${layerName}_source`] = {
      id: `${layerName}_layer`,
      type: `${sourceType.split('-')[0] === 'vector' ? 'vector' : 'raster'}`,
      tiles: [
        `${sourceType.split('-')[0] === 'vector' ? decodeURIComponent(urlMapLibre) : decodeURIComponent(urlMapLibre).replace('geoserver/', 'geoserver/geonode/wms')}`
      ],
      tileSize: sourceType.split('-')[0] === 'vector' ? 512 : 256
    }
    result.styles[`${layerName}_style`] = {
      id: `${layerName}_layer`,
      source: `${layerName}_source`,
      'source-layer': `${layerName.replace('geonode:', '')}`,
      // eslint-disable-next-line no-nested-ternary
      type: sourceType.split('-')[1] === 'extrude' ? 'fill-extrusion' : sourceType.split('-')[0] === 'raster' ? 'raster' : (firstPropName?.split('-')[0]),
      paint: vtPaintStyle,
      layout: {
        visibility: vtVisibilityField
      }
    }
  })
  return result
}
const loadAppConfig = async () => {
  if (config !== null) {
    return
  }
  const url = process.env.REACT_APP_URL_CONFIG
  const baseUrl = process.env.REACT_APP_DATABASE_URL
  const configSources = await Promise.all([
    fetch(url).then((data) => data.json())
  ])

  const layersAndSector = await getLayersAndSector()
  const [internalConfig, layers, categories] = [configSources[0], layersAndSector.layers, layersAndSector.sector[0].categories]
  config = mergeConfig(internalConfig, layers, categories, baseUrl)
}

const getCategories = () => {
  const categoriesOrdenadas = config.categories.map((category) => ({
    id: category.id,
    title: category.title, // config.subCategories[id].title,
    path: icons.find((i) => i.id === category.title)?.path,
    color: icons.find((i) => i.id === category.title)?.color,
    subcategories: category.subcategories
  }))
  return categoriesOrdenadas
}

const getLayer = (id, subCategory) => {
  const layerAux = { ...config?.layers.find((e) => parseInt(Object.keys(e)) === id) }
  const url = config?.baseUrl
  const layer = layerAux[id]
  layer.sourceId = `${layerAux[id].title}_source`
  layer.source = config?.sources[`${layerAux[id].title}_source`]
  layer.style = config?.styles[`${layerAux[id].title}_style`]
  layer.id = id
  layer.subTitle = subCategory
  layer.urlDownload = `${url}geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerAux[id].title}&outputFormat=SHAPE-ZIP`
  layer.link = `${url}layers/${layerAux[id].title}`

  getCategories().forEach((element) => {
    if (element.id === id) layer.color = element.color
  })
  return layer
}

const getSubCategory = (id) => {
  const subCategories = []
  const subcategoriesKeys = config?.categories.find((e) => e.title === id)?.subcategories ?? {}
  Object.keys(subcategoriesKeys).forEach((index) => {
    const { title, layersIds } = subcategoriesKeys[index]

    const layersAux = layersIds?.map((layerId) => ({ layerId, ...getLayer(layerId, title) })) ?? []
    // const layers = layersAux.sort((a, b) => (a.title > b.title ? 1 : -1))

    subCategories.push({
      category: id,
      layers: layersAux,
      title
    })
  })
  return subCategories
}

// TODO: eliminar los que métodos sin utilizar
// Métodos que devuelven mucha data y puede no ser serializable
const getFullLayerConfig = (idGroup, idLayer) => config.layersGroup
  .find((g) => g.id === idGroup)
  .layers.find((l) => l.id === idLayer)

// Métodos que retornan data acotada y segura de serializar

const getLayersGroups = () => config.layersGroup?.map(({ id, title }) => ({
  id,
  title
})) ?? []

const getLayersByLayersGroupId = (layersGroupId) => config.layersGroup
  .find((l) => l.id === layersGroupId)
  .layers.map(({
    id, title, color, index, info, link, reference
  }) => ({
    id,
    title,
    color,
    index,
    info,
    link,
    reference
  }))

const getSubcategoriesLayers = () => {
  const subCategoriLayers = []
  getCategories().forEach((element) => {
    subCategoriLayers.push({
      layers: element.subcategories[0].layersIds,
      title: element.subcategories[0].title,
      color: element.color,
      activas: 0
    })
  })
  return subCategoriLayers
}

const getInformation = () => config.information.map(({
  id, title, description, color
}) => ({
  id,
  title,
  description,
  color
}))

const getDatos = () => config.Datos.map(({
  id, title, description, color
}) => ({
  id,
  title,
  description,
  color
}))

const getUsos = () => config.Usos.map(({
  id, title, description, color
}) => ({
  id,
  title,
  description,
  color
}))

const getEdificabilidad = () => config.Edificabilidad.map(({
  id, title, description, color
}) => ({
  id,
  title,
  description,
  color
}))

const getCategoryTitle = (idCategory) => config.categories.find(({ title }) => {
  const result = title === idCategory
  return result
}).title

const getParcel3D = () => config.parcelLayers.edif_3D

const getParcelLayer = () => config.parcelLayers

const getLayerName = (id) => config?.layers.find((e) => parseInt(Object.keys(e)) === id)[id].title

const getExplorerOptions = () => config.explorer

const getExplorerFilters = () => config.explorerFilters

const getBaseLayers = () => config.baseLayers

const getCustomsIcons = () => config.customIcons

const getSectionInfo = (idTitle) => config.information.find(({ id }) => id === idTitle)

export {
  getExplorerOptions,
  getExplorerFilters,
  getLayerName,
  getSubcategoriesLayers,
  getLayer,
  getDatos,
  getUsos,
  getEdificabilidad,
  getSubCategory,
  loadAppConfig,
  getCategories,
  getFullLayerConfig,
  getLayersGroups,
  getLayersByLayersGroupId,
  getCategoryTitle,
  getParcel3D,
  getParcelLayer,
  getBaseLayers,
  getCustomsIcons,
  getInformation,
  getSectionInfo
}
