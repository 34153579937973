import { createSlice } from '@reduxjs/toolkit'

const layers = createSlice({
  name: 'layers',
  initialState: {
    ids: [],
    filters: {},
    explorerLayers: [],
    layerDefault: [],
    layerInfo: null,
    layerTableData: null,
    checkBoxes: [],
    selectAllChecked:true,
    explorerTitle:''
  },
  reducers: {
    addLayer: (draftState, {
      payload: {
        layerId
      }
    }) => {
      if (!draftState.ids.includes(layerId)) {
        draftState.ids.unshift(layerId)
        draftState.filters[layerId] = {}
        draftState[layerId] = { opacity: 50 }
      }
    },
    removeLayer: (draftState, {
      payload: {
        layerId
      }
    }) => {
      draftState.ids = [...draftState.ids.filter((id) => id !== layerId)]
    },
    orderLayerChange: (draftState, { payload: { startIndex, endIndex } }) => {
      const [removed] = draftState.ids.splice(startIndex, 1)
      draftState.ids.splice(endIndex, 0, removed)
    },
    addFilter: (draftState, {
      payload: {
        layerId,
        cql,
        type
      }
    }) => {
      draftState.filters[layerId][type] = cql
    },
    layerAddDefault: (draftState, action) => {
      draftState.layerDefault = action.payload
    },
    layerSelect: (draftState, action) => {
      draftState.layerInfo = action.payload
    },
    layerInfoData: (draftState, action) => {
      draftState.layerTableData = action.payload
    },
    layerSliderChanged: (draftState, {
      payload: {
        layerId,
        opacity
      }
    }) => {
      draftState[layerId] = { opacity }
    },
    addExplorerLayer: (draftState, action) => {
      draftState.explorerLayers = action.payload
    },
    addExplorerCheckBoxes: (draftState, action) => {
      draftState.checkBoxes = action.payload
    },
    setAllchecked: (draftState, action) => {
      draftState.selectAllChecked = action.payload
    },
    setExplorerTitle: (draftState, action) => {
      draftState.explorerTitle = action.payload
    },
  }
})

export default layers.reducer

const actions = { ...layers.actions }
export { actions }
