import React, { useState, useEffect } from 'react'

import {
  Box, Drawer, makeStyles, SvgIcon, CardActionArea, Badge
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import Categories from 'components/Categories/Categories'
import { actions as mapActions } from 'state/ducks/map'
import { actions } from 'state/ducks/categories'
import Logo from 'components/Logo/Logo'
import { getCategories } from 'utils/configQueries'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import icons from 'utils/svgIcons'
import Media from 'react-media'
import MapaInteractivoGL from 'utils/MapaInteractivoGL'

const useStyles = makeStyles((theme) => ({
  sideBarPaper: {
    width: 80,
    background: theme.palette.secondary.main,
    boxShadow: '0 0 13px -8px rgba(0,0,0,0.75)'
  },
  sideBarNone: {
    BorderRight: 0,
    backgroundColor: 'transparent !important'
  },
  logo: {
    marginTop: theme.spacing(4)
  },
  iconButtonOpen: {
    position: 'absolute',
    padding: 0,
    color: 'white',
    marginTop: 6,
    backgroundColor: '#F3BF00'

  },
  iconButtonClose: {
    padding: 0,
    color: 'white',
    marginTop: 6,
    backgroundColor: '#F3BF00'
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    alignSelf: 'center',
    '&:hover': { backgroundColor: '#ffffff !important' }
  },
  tutorial: {
    paddingBottom: 15
  },
  icon: {
    padding: 10,
    width: 80,
    height: 80
  }
}))

const ConnectedPanel = () => {
  const isMapReady = useSelector((state) => state.map.isMapReady)
  const layersIds = useSelector((state) => state.layers?.ids)
  const [sidebar, setSidebar] = useState(true)
  const showSidebar = () => setSidebar(!sidebar)
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (isMapReady) {
      setData(getCategories())
    }
  }, [isMapReady])

  const classes = useStyles()

  useEffect(() => {
    if (!sidebar) {
      dispatch(actions.setSectionOpen())
    }
    dispatch(actions.setDropDownOpen(sidebar))
  }, [sidebar])

  const title = 'Capas'
  return (
    <Media queries={{ small: { maxWidth: 960 } }}>
      {(matches) => (matches.small
        ? (
          <Drawer
            variant="persistent"
            open
            PaperProps={{ elevation: 0, className: classes.sideBarNone }}
          >
            <Box style={{
              display: 'flex', flexDirection: 'row', height: '100%', background: 'transparent'
            }}
            >
              {sidebar
              && (
              <Box style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3BF00' }}>
                <Box className={classes.logo}>
                  <Logo />
                </Box>
                <Box style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
                >
                  <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '3vh'
                  }}
                  >
                    <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Informacion']))}>
                      <SvgIcon className={classes.icon} component="div">
                        {icons.find((i) => i.id === 'Informacion').path}
                      </SvgIcon>
                    </CardActionArea>
                    <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected([title]))}>
                      <SvgIcon className={classes.icon} component="div">
                        {icons.find((i) => i.id === 'Capas').path}
                      </SvgIcon>
                    </CardActionArea>
                    <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Explorar']))}>
                      <SvgIcon className={classes.icon} component="div">
                        {icons.find((i) => i.id === 'Explorar').path}
                      </SvgIcon>
                    </CardActionArea>
                    <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Reportes']))}>
                      <SvgIcon className={classes.icon} component="div">
                        {icons.find((i) => i.id === 'Reportes').path}
                      </SvgIcon>
                    </CardActionArea>
                    <CardActionArea className={classes.option}>
                      <SvgIcon className={classes.icon} component="div">
                        <a href="https://lujandecuyo.gob.ar/ordenanzas-complementarias/#menu" target="_blanck">
                          {icons.find((i) => i.id === 'Normativa').path}
                        </a>
                      </SvgIcon>
                    </CardActionArea>
                  </Box>
                  <Box className={classes.tutorial}>
                    <CardActionArea
                      className={classes.option}
                      onClick={() => dispatch(mapActions.openTutorial(true))}
                    >
                      <SvgIcon className={classes.icon} component="div">
                        {icons.find((i) => i.id === 'Tutorial').path}
                      </SvgIcon>
                    </CardActionArea>
                    {/* <CardActionArea className={classes.option}>
                  <SvgIcon className={classes.icon} component="div">
                    <Link href="https://sistemamlc.lujandecuyo.gob.ar/test2/auth/login?token=ulPelSBEPARymC5yr9I5Gc9q50YhdGGT">
                      {icons.find((i) => i.id === 'Preguntas').path}
                    </Link>
                  </SvgIcon>
                </CardActionArea> */}
                  </Box>
                </Box>
              </Box>
              )}

              <Box style={{
                backgroundColor: '#F3BF00', height: 35, width: 30, borderTopRightRadius: 10, borderBottomRightRadius: 10, position: 'relative', top: '92%'
              }}
              >
                <IconButton onClick={showSidebar} className={sidebar ? classes.iconButtonClose : classes.iconButtonOpen}>
                  {sidebar ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Box>
            </Box>
          </Drawer>
        ) : (
          <Drawer
            variant="persistent"
            open
            PaperProps={{ elevation: 0, className: classes.sideBarPaper }}
          >
            <Box className={classes.logo}>
              <Logo />
            </Box>
            <Box style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
            >
              <Box style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '3vh'
              }}
              >
                <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Informacion']))}>
                  <SvgIcon className={classes.icon} component="div">
                    {icons.find((i) => i.id === 'Informacion').path}
                  </SvgIcon>
                </CardActionArea>
                <CardActionArea
                  className={classes.option}
                  onClick={() => dispatch(actions.categorySelected([title]))}
                >
                  <SvgIcon className={classes.icon} component="div">
                    <Badge
                      badgeContent={layersIds.length}
                      color="secondary"
                    >
                      {icons.find((i) => i.id === 'Capas').path}
                    </Badge>
                  </SvgIcon>
                </CardActionArea>
                <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Explorar']))}>
                  <SvgIcon className={classes.icon} component="div">
                    {icons.find((i) => i.id === 'Explorar').path}
                  </SvgIcon>
                </CardActionArea>
                <CardActionArea className={classes.option} onClick={() => dispatch(actions.categorySelected(['Reportes']))}>
                  <SvgIcon className={classes.icon} component="div">
                    {icons.find((i) => i.id === 'Reportes').path}
                  </SvgIcon>
                </CardActionArea>
                <CardActionArea className={classes.option}>
                  <SvgIcon className={classes.icon} component="div">
                    <a href="https://lujandecuyo.gob.ar/ordenanzas-complementarias/#menu" target="_blanck">
                      {icons.find((i) => i.id === 'Normativa').path}
                    </a>
                  </SvgIcon>
                </CardActionArea>
              </Box>
              <Box className={classes.tutorial}>
                <CardActionArea
                  className={classes.option}
                  onClick={() => dispatch(mapActions.openTutorial(true))}
                >
                  <SvgIcon className={classes.icon} component="div">
                    {icons.find((i) => i.id === 'Tutorial').path}
                  </SvgIcon>
                </CardActionArea>
                {/* <CardActionArea className={classes.option}>
                  <SvgIcon className={classes.icon} component="div">
                    <Link href="https://sistemamlc.lujandecuyo.gob.ar/test2/auth/login?token=ulPelSBEPARymC5yr9I5Gc9q50YhdGGT">
                      {icons.find((i) => i.id === 'Preguntas').path}
                    </Link>
                  </SvgIcon>
                </CardActionArea> */}
              </Box>
            </Box>
          </Drawer>
        ))}
    </Media>
  )
}

export default ConnectedPanel
