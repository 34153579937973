/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Box, Link } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { actions as mapActions } from 'state/ducks/map'
import { actions as seekerActions } from 'state/ducks/seeker'
import BaseLayersControl from 'components/BaseLayersControl/index'
import Button3d from 'components/Button3d'
import MapaInteractivoGL from 'utils/MapaInteractivoGL'
import { actions as layersActions } from 'state/ducks/layers'
import { actions } from 'state/ducks/categories'
import Seeker from 'components/Seeker/Seeker'
import LayersBoxes from 'components/LayersBoxes/index'
import { useTour } from '@reactour/tour'
import PropTypes from 'prop-types'
import Measure from 'components/Measure'
import useZoom from 'components/Seeker/useZoom'
import Popup from 'components/Popup'
import Scrollbar from 'react-smooth-scrollbar'
import Media from 'react-media'
import { getSubCategory } from 'utils/configQueries.js'
import {
  MaplibreExportControl,
  Size,
  PageOrientation,
  Format,
  DPI
} from '@watergis/maplibre-gl-export'
import Tutorial from 'components/Tutorial'
import KANlogoID from '../../img/lujan.png'
import logoKan from '../../img/logo-principal.png'
import useStyles from './styles'
import '@watergis/maplibre-gl-export/css/styles.css'
import { zIndex } from 'material-ui/styles'

const transformRequest = (url, resourceType) => {
  const token = '7b3ea1f12563ee390a13ab885884e4590cf6de26'
  if (resourceType === 'Tile' && url.endsWith('pbf')) {
    return {
      url,
      headers: { Authorization: `Token ${token}` }
      // credentials: 'include'  // Include cookies for cross-origin requests
    }
  }
  return { url }
}

const Map = ({ children }) => {
  const text = []
  const classes = useStyles()
  const isMapReady = useSelector((state) => state.map.isMapReady)
  const cameraLat = useSelector((state) => state.map.camera.lat)
  const cameraLng = useSelector((state) => state.map.camera.lng)
  const cameraZoom = useSelector((state) => state.map.camera.zoom)
  const cameraPitch = useSelector((state) => state.map.camera.pitch)
  const cameraBearing = useSelector((state) => state.map.camera.bearing)
  const openTutorial = useSelector((state) => state.map.tutorial)
  const sectionId = useSelector((state) => state.categories.sectionId)
  const lng = useSelector(
    (state) => state.location.place.location?.coordinates[0]
  )
  const lat = useSelector(
    (state) => state.location.place.location?.coordinates[1]
  )
  const coordinates = useSelector(
    (state) => state.location.place?.location?.coordinates
  )
  const selectedSuggestion = useSelector((state) => state.location.place)
  const coord = useSelector((state) => state.map.selectedCoords)
  const clickedPoint = useSelector((state) => state.map.clickedPoint?.x)
  const showLine = useSelector((state) => state.measure?.showLine)
  const layersIds = useSelector((state) => state.layers?.ids)
  const info = useSelector((state) => state.layers.layerInfo)
  const layersDefaults = useSelector((state) => state.layers.layerDefault)
  const [isExpanded, setIsExpanded] = useState(false)
  const [mapGL, setMapGL] = useState(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const { setIsOpen } = useTour()
  useEffect(() => {
    if (isMapReady) {
      mapGL.map.addControl(
        new MaplibreExportControl({
          PageSize: Size.A3,
          PageOrientation: PageOrientation.Portrait,
          Format: Format.PNG,
          DPI: DPI[96],
          Crosshair: true,
          PrintableArea: true
        }),
        'top-right'
      )

      const box = document.querySelector('.mapboxgl-ctrl-group')
      const capture = document.querySelectorAll(
        '.mapboxgl-ctrl.mapboxgl-ctrl-group'
      )[3]
      if (capture) {
        capture.classList.add(classes.buttonCapture)
        const buttonConteiner = document.querySelectorAll(
          '.mapboxgl-ctrl-group button'
        )
        Object.keys(buttonConteiner).map((div) => buttonConteiner[div]
          .classList.add(classes.buttonControlMap))
        box.classList.add(classes.buttons)

        const fullscreen = document.querySelector('.mapboxgl-ctrl-fullscreen')
        const geolocate = document.querySelector('.mapboxgl-ctrl-geolocate')
        const line = document.querySelector('#buttonLine')
        const zoomIn = document.querySelector('.maplibregl-ctrl-zoom-in')
        const zoomOut = document.querySelector('.maplibregl-ctrl-zoom-out')
        const compass = document.querySelector('.maplibregl-ctrl-compass')
        fullscreen.title = 'Pantalla Completa'
        geolocate.title = 'Encuentra mi Ubicación'
        zoomIn.title = 'Acercarse'
        zoomOut.title = 'Alejarse'
        compass.title = 'Norte'
        line.title = 'Medicion'
        capture.title = 'Captura del Mapa'
        box.insertBefore(geolocate, box.theFirstChild)
        box.insertBefore(line, box.theFirstChild)
        box.insertBefore(fullscreen, box.theFirstChild)
      }
    }
  }, [classes, isMapReady, mapGL])

  useEffect(() => {
    if (isMapReady) {
      const url = location.hash.split('/')
      if (url.length < 2) {
        mapGL.map.flyTo({
          center: [cameraLng, cameraLat],
          zoom: cameraZoom,
          pitch: cameraPitch,
          bearing: cameraBearing
        })
      } else {
        mapGL.map.flyTo({
          center: [url[2], url[1]],
          zoom: Number(url[0].split('#')[1]),
          pitch: url[4] ? url[4] : cameraPitch,
          bearing: url[3] ? url[3] : cameraBearing
        })
      }
    }
  }, [
    isMapReady,
    mapGL,
    cameraLat,
    cameraLng,
    cameraZoom,
    cameraBearing,
    cameraPitch
  ])

  useEffect(() => {
    if (isMapReady) {
      mapGL.map.flyTo({
        pitch: cameraPitch
      })
    }
  }, [
    isMapReady,
    mapGL,
    cameraPitch
  ])

  useEffect(() => {
    if (isMapReady) {
      mapGL.map.setMaxZoom(18.4)
    }
  }, [isMapReady, mapGL])

  useEffect(() => {
    if (isMapReady) {
      setIsOpen(openTutorial)
    }
  }, [isMapReady, setIsOpen, openTutorial])

  useEffect(() => {
    if (isMapReady) {
      setIsOpen(openTutorial)
    }
  }, [isMapReady, setIsOpen, openTutorial])
  // useEffect(() => {
  //   if (!layersIds[0]) {
  //     dispatch(mapActions.removeCoords())
  //   }
  // }, [layersIds, coord])
  useEffect(() => {
    if (isMapReady && mapGL && coord && layersIds[0] && !showLine) {
      mapGL.map.flyTo({
        center: [coord?.lng, coord?.lat]
      })
    }
  }, [coord, isMapReady, mapGL])

  const onClicked = useCallback(
    ({
      lng: _lng, lat: _lat, x, y
    }) => {
      dispatch(
        mapActions.clickOnMap({
          lng: _lng,
          lat: _lat,
          x,
          y
        })
      )
    },
    [dispatch]
  )

  const onRightClicked = useCallback(
    ({ lng: __lng, lat: __lat }) => {
      const coords = { lng: __lng, lat: __lat }
      dispatch(mapActions.rightClickOnMap(coords))
    },
    [dispatch]
  )
  const defaultMapStyle = useSelector((state) => state.map.defaultMapStyle)

  useEffect(() => {
    dispatch(mapActions.loadLayers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Se inicializa el mapa
  useEffect(() => {
    if (!isMapReady && defaultMapStyle !== null) {
      const map = MapaInteractivoGL({
        params: {
          center: [cameraLng, cameraLat],
          zoom: cameraZoom,
          style: defaultMapStyle
        },
        transformRequest,
        onClicked,
        onRightClicked
      })
      setMapGL(map)

      dispatch(mapActions.initMap(map))
    }
  }, [
    isMapReady,
    defaultMapStyle,
    cameraLat,
    cameraLng,
    cameraZoom,
    onClicked,
    dispatch,
    onRightClicked
  ])

  useZoom({ lng, lat, map: mapGL?.map })

  useEffect(() => {
    if (selectedSuggestion) {
      const data = [selectedSuggestion]?.map(
        ({ properties }) => properties && Object.entries(properties)
      )
      data.map((item) => item?.forEach((item1, e) => {
        text[e] = item1
      }))
    }
  }, [selectedSuggestion, text])

  useEffect(() => {
    if (isMapReady && !(sectionId[0] === 'Informacion')) {
      dispatch(actions.categorySelected(['Informacion']))
    }
  }, [clickedPoint])

  useEffect(() => {
    if (isMapReady) {
      const layerDefault = getSubCategory('Capas iniciales').map(({ layers, title }) => layers?.map((e) => ({ ...e, info: `category-${title}` }))).flatMap((e) => e)
      dispatch(layersActions.layerAddDefault(layerDefault))
    }
  }, [dispatch, isMapReady])

  const removeAndAddLayer = ({ layer }) => {
    const { map } = MapaInteractivoGL()

    if (map) {
      const existLayer = map.getLayer(layer.id)
      if (existLayer) {
        map.removeLayer(layer.id)
        map.addLayer(layer)
      } else {
        map.addLayer(layer)
      }
    }
  }

  useEffect(() => {
    if (isMapReady) {
      mapGL.map.easeTo({
        padding: { left: 300 },
        duration: 0
      })
    }
  }, [isMapReady])

  useEffect(() => {
    if (isMapReady && info) {
      dispatch(
        mapActions.cameraUpdated({
          pitch: 60
        })
      )
    }
  }, [info])

  useEffect(() => {
    if (coord?.lng && info) {
      setTimeout(() => {
        mapGL.map.flyTo({
          center: [coord?.lng, coord?.lat],
          zoom: 18.4
        })
      }, 500)
    }
  }, [coord?.lat, coord?.lng, info])

  useEffect(() => {
    if (isMapReady && layersDefaults[0]) {
      const { map } = MapaInteractivoGL()
      const layerInfo = layersDefaults.find(({ info }) => info?.includes('category-Información')).source.id
      const layerExtrucion = layersDefaults.find(({ info }) => info?.includes('category-Extrusión'))
      map.on('data', () => { map.moveLayer(layerExtrucion.source.id) })

      map.on('click', layerInfo, (e) => {
        const { id } = e.features[0].properties
        const { properties } = e.features[0]
        const {
          sourceId, source: { id: idLayer }, style: { type, 'source-layer': sourceLayer, paint }
        } = layerExtrucion
        const layer = {
          id: idLayer,
          type,
          source: sourceId,
          'source-layer': sourceLayer,
          paint,
          filter: [
            '==',
            'id',
            id
          ]
        }
        dispatch(layersActions.layerSelect(properties))
        removeAndAddLayer({
          layer
        })
      })
    }
  }, [isMapReady, layersDefaults, mapGL?.map, layersIds])

  return (
    <>
      <Container id="map" className={classes.container}>
        <Box>
          {coordinates && !showLine && (
            <Popup coords={coord} offset={20}>
              <div style={{ paddingTop: 10 }}>
                <Scrollbar>
                  <div
                    style={{
                      maxHeight: 300,
                      paddingRight: 9,
                      paddingLeft: 9
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        backgroundColor: '#0b72bb',
                        color: 'white',
                        padding: 8,
                        width: '100%',
                        borderRadius: '4px'
                      }}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {selectedSuggestion?.title}
                    </button>
                    {isExpanded && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {text.map(([key, value]) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <h4>
                              {key}
                              :
                            </h4>
                            <p style={{ lineHeight: '100%', paddingLeft: 5 }}>
                              {value}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Scrollbar>
              </div>
            </Popup>
          )}
        </Box>
        <LayersBoxes />
        <Box className={classes.bottomMenu}>
          <Button3d />
          <BaseLayersControl
            width={64}
            height={50}
            gap={6}
            layers={[
              {
                id: 'baseLayer_principal',
                imageUrl:
                    'url("https://raw.githubusercontent.com/ign-argentina/argenmap/master/src/styles/images/argenmap.png")'
              },
              {
                id: 'baseLayer_secundario',

                imageUrl:
                    'url("https://raw.githubusercontent.com/ign-argentina/argenmap/master/src/styles/images/argenmap-gris.png")'
              },
              {
                id: 'baseLayer_parcela',
                imageUrl: 'url("assets/osm_argentina.png")'
              },
              {
                id: 'baseLayer_satelital',
                imageUrl: 'url("assets/satelital.png")'
              }
            ]}
            onLayerSelected={(idLayerSelected) => {
              mapGL.setBaseLayer(idLayerSelected)
            }}
          />
          {isMapReady && <Measure />}

          <Box className={classes.topMenu}>
            <Tutorial />
            <Seeker />
            <div className={classes.logoPrincipal}>
              <a href="https://lujandecuyo.gob.ar/">
                <img
                  alt="1"
                  src={logoKan}
                />
              </a>
            </div>
            {
              // logged ? <LogoutButton /> : null
            }
          </Box>
        </Box>
        {isMapReady && children}
      </Container>
      {' '}
    </>
  )
}

Map.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired
}

export default Map
