import React from 'react'

import ContainerBar from 'components/Sections/ContainerBar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import { getSubCategory } from 'utils/configQueries'
import { useSelector } from 'react-redux'
import icons from 'utils/svgIcons'
import useStyles from './styles'

const ThematicMaps = ({

  title, options, selected, onChangeSelected
}) => {
  const classes = useStyles()
  const idCategory = useSelector((state) => state.categories.sectionId[0])
  const layers = getSubCategory(idCategory).map(({ layers }) => layers).flatMap((e) => e)
  const dataLayers = options?.map((element) => element)

  let lastTitle = null
  dataLayers.forEach((_, i) => {
    if (dataLayers[i]?.title === lastTitle) {
      dataLayers[i].title = null
    } else {
      lastTitle = dataLayers[i].title
    }
  })

  const data = dataLayers.map(({ title }) => title)
  const subTitle = data?.filter((data) => data !== null)

  const subcategoryLayers = subTitle?.map((data) => ({
    title: data,
    layers: layers.filter(({ subTitle }) => subTitle === data)
  }))
  return (
    <ContainerBar style={{ padding: '2' }} type="list">
      <Box data-tut="subcategory">
        <Box className={classes.container}>
          <Box style={{ padding: '0' }}>
            {icons.find((i) => i.id === 'CapasDrawer')?.path}
          </Box>
          <Typography className={classes.sectionTitle}>
            Capas
          </Typography>
        </Box>
        <FormGroup className={classes.formGroup}>
          {subcategoryLayers?.map(({
            title, layers
          }) => (
            <Box className={classes.subCategory}>
              <Accordion key={title}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  {!!title && (
                    <>
                      {title}
                      <br />
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails expandIcon={<ExpandMoreIcon />} style={{ display: 'flex', flexDirection: 'column' }}>
                  {layers.map(({ name, title, id }) => {
                    const isChecked = selected?.includes(id)
                    return (
                      <>
                        <FormControlLabel style={{ marginLeft: 0 }}
                          className={classes.subtile}
                          key={title}
                          control={(
                            <Checkbox
                              checked={isChecked}
                              onChange={() => onChangeSelected(id, !isChecked)}
                            />
                          )}
                          label={(
                            <Typography className={classes.capa}>
                              {name}
                            </Typography>
                          )}
                        />
                        <Divider style={{ display: 'none'}} />
                      </>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </FormGroup>
      </Box>
    </ContainerBar>
  )
}

export default ThematicMaps
