import React from 'react'

import { Drawer } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'

import ThematicMaps from 'components/Sections/SubSection/ThematicMaps'
import Reports from 'components/Sections/SubSection/Reports'
import Information from 'components/Sections/SubSection/Information'
import Explore from 'components/Sections/SubSection/Explore'
import { actions as layersActions } from 'state/ducks/layers'
import { useSection } from './useSection'

const Section = () => {
  const dispatch = useDispatch()
  const isShow = useSelector((state) => state.categories.sectionOpen)
  const sectionId = useSelector((state) => state.categories.sectionId)
  const lastIndex = sectionId.length - 1
  const sectionSelected = sectionId[lastIndex]
  const { title, options } = useSection(sectionSelected)
  const layerIds = useSelector((state) => state.layers.ids)
  const handlerChangeSelected = (layerId, isSelected) => {
    const action = isSelected
      ? layersActions.addLayer
      : layersActions.removeLayer
    dispatch(action({ layerId }))
  }
  return (
    <Drawer variant="persistent" open={isShow}>
      {(isShow && sectionId[0] === 'Capas') && (
        <ThematicMaps
          title={title}
          options={options}
          selected={layerIds}
          onChangeSelected={handlerChangeSelected}
        />
      )}
      {(isShow && sectionId[0] === 'Informacion') && (
        <Information />
      )}
      {(isShow && sectionId[0] === 'Reportes') && (
      <Reports />
      )}
      {(isShow && sectionId[0] === 'Explorar') && (
      <Explore />
      )}
    </Drawer>
  )
}

export default Section
